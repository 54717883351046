import clsx from 'clsx';
import {
  AllHTMLAttributes,
  CSSProperties,
  ElementType,
  HTMLAttributes,
  ReactNode,
  createElement
} from 'react';

export type FlexProps = AllHTMLAttributes<HTMLElement> & {
  as?: ElementType;
  alignment?: 'center' | 'flex-start' | 'flex-end';
  spacing?: 'center' | 'space-around' | 'space-between' | 'space-evenly';
  gap?: string;
  className?: string;
  children?: ReactNode;
  style?: CSSProperties;
};

export function FlexRow({
  as,
  alignment,
  spacing,
  gap,
  children,
  style,
  className,
  ...props
}: FlexProps) {
  return createElement(as ?? 'div', {
    className: clsx('flex', className),
    children: children,
    style: {
      alignItems: alignment,
      justifyContent: spacing,
      gap,
      ...style
    },
    ...props
  });
}

export function FlexColumn({
  as,
  alignment,
  spacing,
  gap,
  children,
  style,
  className,
  ...rest
}: FlexProps) {
  return createElement(as ?? 'div', {
    className: clsx('flex flex__col', className),
    children: children,
    style: {
      alignItems: alignment,
      justifyContent: spacing,
      gap,
      ...style
    },
    ...rest
  });
}
